import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Header from '../components/Header'
import MainWrapper from '../containers/MainWrapper'
import SEO from '../components/SEO'

const Post = styled.div`
  p {
    color: ${props => props.theme.colors.grey};
    text-align: left;
    font-family: 'Source Sans Pro','Roboto','Helvetica','Arial',sans-serif;
    font-size: 20px;
    margin: 3rem 0;
  }
`

const Head = styled.div`

  background: #111111;
  > div {
    max-width: ${props => props.theme.maxWidth};
    margin: 0 auto 3rem;
    padding: 2rem;
    
    &> div {
      float: left;
      width: 50%;
      padding: 1rem;
      @media (max-width: ${props => props.theme.breakpoints.m}) {
        float: none;
        width: 100%;
      }
      .logoWrapper {
        
        img {
          max-height: 50px;
          display: block;
          margin: 0 auto;
          filter: grayscale(70%);
        }
      }
      p {
        font-size: 20px;
        font-family: 'Source Sans Pro','Roboto','Helvetica','Arial',sans-serif;
        color: ${props => props.theme.colors.grey};
        text-align: center;
      }
    }
    &:after {
      display: block;
      float: none;
      clear: both;
      content: "";
    }
  }
`;

const Presse = ({ location, data: { presse, pressexcerpt } }) => {
  const headArticles = pressexcerpt.data.articles;
  const articles = presse.edges[0].node.data.articles;
  
  return (
    <React.Fragment>
      <SEO 
        title={`Alexx & Mooonshiners | Presse`}
        desc={`Articles de presse`}
        pathname={location.pathname}
      />
      <Header location={location} />
      <Head>
        <div>
          {
            headArticles.map((item, index) => {
              return (
                <div key={`head-article-${index}`}>
                  <div className="logoWrapper">
                    <img 
                      src={item.image.localFile.publicURL}
                      alt={item.texte.text}
                    />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: item.texte.html }}></div>
                </div>
              )
            })
          }
        </div>
      </Head>
      <MainWrapper>
        {
          articles.map((item, index) => {
            return (
              <Post key={`article-${index}`} dangerouslySetInnerHTML={{ __html: item.article.html }}></Post>
            )
          })
        }
      </MainWrapper>
    </React.Fragment>
  )
}

export default Presse;
export const pageQuery = graphql`
  query PressArticles {
    presse: allPrismicPresse {
      edges {
        node {
          data {
            articles {
              article {
                html
              }
            }
          }
        }
      }
    }
    pressexcerpt: prismicPressexcerpt {
      data {
        articles {
          image {
            localFile {
              publicURL
            }
          }
          texte {
            text
            html
          }
        }
      }
    }
  }
`